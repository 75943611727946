@mixin optionFonts() {
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: #303030;
}

.smart-search {
  margin-bottom: 100px;
}

.step-one-wrapper {
  margin-top: 20px;
  width: 100%;

}

.smart-search-step-one {
  display: grid;
  grid-template-columns:repeat(3,1fr);
  gap: 40px;
}


.smart-saerch-item-wrapper {

  max-width: 407px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity .1s ease;

  div {


    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    span {
      margin-top: 15px;
      text-align: center;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;


    }


  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    transition: opacity .1s ease;
  }
}

.step-one-description {
  margin-top: 20px;
}

.step-one-img-wrapper {
  width: 100%;
  min-height: 300px;
  height: auto;
  overflow: auto;
  span{
    position: relative !important;
    height: 100% !important;
    img{
      width: 100% !important;
      max-height: 400px !important;
      height: 100% !important;
      @media (max-width: 475px){
        object-fit: contain;
      }
    }
  }
  @media (max-width: 475px){
    min-height: 150px;
  }
}

.smart-search-page-second {
  background-color: rgb(240 244 245);
  padding: 20px 12px;
  display: flex;
  flex-direction: column;

}

.smart-search-optional-steps {

  width: 100%;
  margin: 0 auto;
}


.new-search-feature-top {

  display: flex;
  flex-direction: column;
  gap: 12px;

  .new-search-feature {
    justify-content: flex-start;
  }

  > .smart-search-sort-container {
    margin: unset;
  }
}

.smart-search-products {
  margin-top: 30px;
}

.smart-search-sort-container {
  position: relative;
  margin-top: 12px;
}

.smart-search-sort-wrapper {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.5px solid #707070;
  border-radius: 4px;
  background: #FFFFFF;
  margin-top: 10px;
}

.smart-search-sort {
  width: 100%;

  span {
    @include optionFonts
  }
}

.search-smart-enabled {

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  cursor: pointer;

  span {
    font-size: 20px;
  }
}

.search-smart-disabled {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #00000026;
  opacity: .35;
  padding: 10px 12px;
}

.smart-search-opened-sort {
  width: 100%;
  max-height: 225px;
  position: absolute;
  overflow-y: scroll;
  background: white;
  z-index: 2;
  border-radius: 4px;
}

.selected-sort-option {
  background: #E4E4E4;
}

.smart-search-sort-option {

  border: 0.5px solid #707070;
  border-top: unset;
  padding: 10px 12px;
  cursor: pointer;

  span {
    @include optionFonts
  }
}

.new-search-feature {

  width: fit-content;
  display: flex;
  justify-content: center;

  div {
    max-width: 300px;
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $main-theme-color-dark;
    border-radius: 4px;
    padding: 10px 20px;

    &:hover {
      cursor: pointer;
      background: $main-theme-color-hover;
    }

    span {
      text-align: center;
      color: #FFFFFF;
    }
  }

}

.search-smart-for-step-one {
  width: fit-content;

  .search-smart-enabled {
    gap: 20px;
  }
}

.smart-search-opened-sort-step-one {
  .smart-search-sort-option {
    background: white;
    border: unset;
  }
}

.search-products-card {
  .product-card__availability {
    display: none;
  }
}


@media (max-width: 991px) {

  .smart-search-step-one {
    grid-template-columns: repeat(2,1fr);
    gap: 20px;
  }
  .smart-search-optional-steps {
    grid-template-columns: 100%;
  }
  .new-search-feature-top {
    > .smart-search-sort-container {
      width: 100%;
    }
  }
}